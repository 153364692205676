import React from 'react'
import { Link } from 'gatsby'
import icon1 from '../../../assets/images/services/service-icon1.png'
import icon2 from '../../../assets/images/services/service-icon2.png'
import icon3 from '../../../assets/images/services/service-icon3.png'

const RelatedServices = () => {
    return (
        <section className="services-area pt-100 pb-70 bg-f1f8fb">
            <div className="container">
                <div className="section-title">
                    <h2>Mai multe servicii care v-ar putea interesa</h2>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box ">
                            <div className="icon">
                                <img src={icon1} alt="about" />
                            </div>
                            <h3>
                                <Link to="/servicii/transformare-digitala/">
                                    Transformare digitală
                                </Link>
                            </h3>
                            <p>Experimentați beneficiile transformării digitale: eficiență crescută, experiențe client mai bune și un avantaj competitiv pe piață.</p>

                            <Link to="/servicii/transformare-digitala/" className="read-more-btn">
                                Vezi Detalii <i className="flaticon-right"></i>
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon2} alt="about" />
                            </div>
                            <h3>
                                <Link to="/servicii/management-proiect/">
                                     Management de proiect
                                </Link>
                            </h3>
                            <p>Eficientizați procesul de dezvoltare software și duceți proiectele la bun sfârșit cu echipa noastră experimentată de management de proiect.</p>

                            <Link to="/servicii/management-proiect/" className="read-more-btn">
                                Vezi Detalii <i className="flaticon-right"></i>
                            </Link>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon3} alt="about" />
                            </div>
                            <h3>
                                <Link to="/servicii/mentenanta-aplicatii/">
                                    Management și mentenanță aplicații
                                </Link>
                            </h3>
                            <p>Păstrați-vă aplicațiile funcționând fără probleme cu serviciile noastre de management și mentenanță de aplicații.</p>

                            <Link to="/servicii/mentenanta-aplicatii/" className="read-more-btn">
                                Vezi Detalii <i className="flaticon-right"></i>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default RelatedServices
import React from 'react'
import ServiceSidebar from './ServiceSidebar'
import details1 from '../../../assets/images/services/services-details1.jpg'
import project2 from '../../../assets/images/projects/project2.jpg'
import charts from '../../../assets/images/services/charts.jpg'

const ServiceDetailsContent = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={details1} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <span className="sub-title">Soluții inovatoare</span>
                            <h3>Despre dezvoltarea și migrarea cloud</h3>
                            <p>Migrarea la cloud se referă la procesul de mutare a aplicațiilor, a datelor și a infrastructurii unei organizații de la sistemele on-premises la cloud. Migrarea la cloud oferă multe beneficii, inclusiv o scalabilitate îmbunătățită, fiabilitate și securitate. La Vertical Digital, avem o experiență extinsă în ajutarea organizațiilor să migreze la cloud fără probleme și cu succes.</p>
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>Procesul nostru</h3>
                                        <p>Procesul nostru de migrare la cloud începe cu o evaluare amănunțită a mediului IT actual al organizației dvs. și a gradului de pregătire pentru cloud. Lucrăm cu dvs. pentru a vă defini obiectivele de migrare la cloud și a dezvolta un plan pentru a le atinge.</p>
                                        <p>Apoi, vă ajutăm să migrați aplicațiile, datele și infrastructura la cloud. Acest lucru poate implica refondarea aplicațiilor dvs. pentru a profita de caracteristicile native ale cloud-ului, migrarea datelor către stocarea în cloud și implementarea infrastructurii bazate pe cloud.</p>
                                        <p>În timpul procesului, oferim suport și orientare continuă pentru a asigura succesul migrației la cloud. Lucrăm cu dvs. pentru a monitoriza progresul, a identifica orice provocări și a face orice ajustări necesare pentru a vă asigura că puteți atinge rezultatele dorite.</p>
                                    </div>
                                </div>
                            </div>

                            <p>La Vertical Digital, suntem dedicați ajutorării organizațiilor să migreze cu succes la cloud. Echipa noastră de experți are cunoștințele și experiența necesare pentru a vă ghida prin procesul de migrare la cloud, de la început până la sfârșit. Contactați-ne astăzi pentru a afla mai multe despre cum vă putem ajuta să profitați de multele beneficii ale computării în cloud.</p>
                            <h3>Arii de aplicabilitate</h3>

                            <div className="row">
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-factory"></i>
                                        </div>
                                        Producție
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Sănătate
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        Automotive
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        Banking
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Imobiliare
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Logistică
                                    </div>
                                </div>
                            </div>

                            <h3>Tehnologii folosite de noi</h3>
                            <ul className="technologies-features">
                                <li><span>AWS</span></li>
                                <li><span>Azure</span></li>
                                <li><span>Google Cloud</span></li>
                                <li><span>Docker</span></li>
                                <li><span>Kubernetes</span></li>
                                <li><span>Terraform</span></li>
                                <li><span>Ansible</span></li>
                                <li><span>Kibana</span></li>
                                <li><span>Snowflake</span></li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebar />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContent